<template>
  <wsDialog
      v-model="$store.state.avalonPage.displayPageDeleteDialog"
      :title="$t('avalon.page.editor.delete.title')"
      @save="deletePage"
      :save-text="$t('Delete')"
  >

    <h5>{{ $t('avalon.page.editor.delete.text') }}</h5>


  </wsDialog>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "avalonCopyPageDialog",
  props : {
    page : {
      type : String
    }
  },
  data() {
    return {
      entityData : {}
    }
  },
  computed : {
    ...mapState('avalon' , [ 'selectedLang' , 'homepage']),
    ...mapState('avalonPage' , ['selectedPage' , 'currentPage'])
  },
  methods : {
    ...mapActions('avalonPage' , [ 'DELETE_PAGE' ]),

    async deletePage() {
      let result = await this.DELETE_PAGE(this.selectedPage)
      if ( !result ) {
        return
      }

      let index = this.$store.state.avalon.pagesSelect.findIndex( el=> el.value === this.selectedPage)
      if ( index === -1 ) {
        this.$store.state.avalon.displayNewPageDialog = false
        return
      }
      this.$store.state.avalon.pagesSelect.splice(index,1)

      if ( this.homepage[this.selectedLang] === this.selectedPage ) {
        this.homepage[this.selectedLang] = null
      }

      if ( this.selectedPage == this.currentPage ) {
        this.$router.push(this.businessPublicLink(`page/${(this.homepage[this.selectedLang] + '/editor') || (this.selectedLang + '/blank_homepage') }`) )
      }

      this.$store.state.avalonPage.displayPageDeleteDialog = false

    }
  }
}
</script>

<style scoped>

</style>